import './App.css'
import {Route, Routes, useLocation} from "react-router-dom";
import {ROUTES} from "./lib/consts";
import PrivateRoute from "./helpers/PrivateRoute";
import Footer from "./globalComponents/footer/footer";
import NavBarComponent from "./globalComponents/navBarComponent/navBarComponent";
import React, {Suspense, useEffect} from "react";
import Spinner from "./globalComponents/spinner/spinner";
import Services from "./pages/servicepage/Services";
import WebDevSlider from "./pages/homePage/components/webdevSlider/WebDevSlider";
import GameServices from "./pages/gameServicePage/GameServices";
import DesignServices from "./pages/designServicesPage/DesignServices";
import SecurityServices from "./pages/cyberSecurity/SecurityServices";
import CloudServices from "./pages/cloudIntrigation/CloudServices";
import InternetThingServices from "./pages/internetThing/InternetThingServices";
import ProductServices from "./pages/productServicePage/ProductServices";
import DigitalMServices from "./pages/digitalMService/DigitalMServices";

import DevelopmentServices from "./pages/developmentServicePage/DevelopmentServices";
import Career from './pages/career/Career';
import {Helmet} from 'react-helmet';
import MainServices from "./pages/mainServicePage/MainServices";


function App() {

    const HomePage = React.lazy(() => import('./pages/homePage/HomePage'));
    const OurWorks = React.lazy(() => import('./pages/ourWorksPage/OurWorksPage'));
    const AboutUs = React.lazy(() => import('./pages/aboutus/AboutUsPage'));
    const Contactus = React.lazy(() => import('./pages/contactus/ContactUs'));
    const MainServices = React.lazy(() => import('./pages/mainServicePage/MainServices'));


    return (
        <div className="App">

            <Suspense fallback={<Spinner/>}>

                <NavBarComponent/>

                <Routes>

                    <Route path={ROUTES.LOGIN} element={<p>LOGIN</p>}/>
                    <Route path={ROUTES.HOME_PAGE} element={<HomePage/>}/>
                    <Route
                        path={ROUTES.PRIVATE_ROUTE}
                        element={
                            <PrivateRoute>
                                <p>Your Private route component here</p>
                            </PrivateRoute>
                        }
                    />
                    <Route path={ROUTES.OURWORKS_PAGE} element={<OurWorks/>}/>
                    <Route path={ROUTES.ABOUTUS_PAGE} element={<AboutUs/>}/>
                    <Route path={ROUTES.CONTACTUS_PAGE} element={<Contactus/>}/>
                    <Route path={ROUTES.CAREERS_PAGE} element={<Career/>}/>
                    <Route path={ROUTES.SERVICE_PAGE} element={<Services/>}/>
                    <Route path={ROUTES.GAMESERVICE_PAGE} element={<GameServices/>}/>
                    <Route path={ROUTES.DESIGNSERVICE_PAGE} element={<DesignServices/>}/>
                    <Route path={ROUTES.SECURUTISERVICE_PAGE} element={<SecurityServices/>}/>
                    <Route path={ROUTES.CLOUDSERVICE_PAGE} element={<CloudServices/>}/>
                    <Route path={ROUTES.INTERNETSERVICE_PAGE} element={<InternetThingServices/>}/>
                    <Route path={ROUTES.PRODUCTSERVICE_PAGE} element={<ProductServices/>}/>
                    <Route path={ROUTES.DIGITALMSERVICE_PAGE} element={<DigitalMServices/>}/>
                    <Route path={ROUTES.DEVELOPMENTSERVICE_PAGE} element={<DevelopmentServices/>}/>
                    <Route path={ROUTES.MAINSERVICE_PAGE} element={<MainServices/>}/>

                </Routes>
                <Footer/>
            </Suspense>

        </div>
    );
}

export default App;
