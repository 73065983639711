import './style.scss'
import GameCard from "../../components/card/GameCard";
import {GameserviceCardConst} from "../../../../constantFiles/constants";
import DesignservicesCard from "../../../designServicesPage/components/servicesCard/DesignservicesCard";
import GameservicesCard from "../../components/servicesCard/GameservicesCard";


export default function GameCardSection() {
    return (
        <section className="serviceCardSection">

            <div className="serviceCardSection_heading">
                <h1>our GAME DEVELOPMENT services</h1>
            </div>
            <div className="gameserviceCardContent">
                {
                    GameserviceCardConst.map(({heading, calssNames, content, secoundHeading}) => (

                        <GameservicesCard title={heading} secondTitle={secoundHeading} alt={''} classh2={calssNames} classh1={''}
                                            description={content}
                                            image={''}/>
                    ))
                }
            </div>

        </section>
    )
}