import './style.scss'
import service_image from '../../../../assets/servicePage/heropage/service_img.png'


export default function DesignHeroSection() {
    return (
        <section className='service-heroSection'>
            <div className="service-main">
            <div className="service-heroSection_left">
                <h1>DESIGN</h1>
                <h2>Embrace design that captivates, communicates, and leaves a lasting impression.</h2>
            </div>
            <div className="service-heroSection_right">
                <img src={service_image}/>
            </div>
            </div>


        </section>
    )
}