import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./style.scss";
import { Link } from "react-router-dom";
import CaretLeft from "../../assets/CaretLeft.png";
import arrowImg from "../../assets/careers/uil_arrow-up.png";
import { ROUTES } from "../../lib/consts";

export default function Career() {
  const [selectedRole, setSelectedRole] = useState("");

  const jobs = [
    // {
    //   id: "123123",
    //   about: "Experience: 5+ years",
    //   qualifications: `Primary Skills:
    //   Angular, HTML / CSS, JavaScript, Nginx, TypeScript
    //   Assessment to be Cleared:
    //   JavaScript, Angular, HTML5 Semantics, Typescript`,
    //   responsibilities:
    //     "They are currently seeking a highly skilled Senior Frontend Engineer to join their team. Their company is dedicated to developing cutting-edge web applications for enterprise supply chain-related businesses, and they need someone who shares their passion for innovation and excellence.As a Senior Frontend Engineer, you will be responsible for developing and maintaining complex web applications, ensuring high performance and responsiveness of their applications. You will work closely with other team members and stakeholders to understand their needs and develop solutions that meet their requirements.",
    //   role: "Frontend developer ",
    //   location: "FULL-TIME, BENGALURU",
    // },
    {
      id: "345",
      about: "Salary and benefits are commensurate with industry standards and candidate experience. We always strive to provide competitive compensation packages that reward and recognise the hard work and dedication of our employees.",
      qualifications: "Excellent communication skills and a minimum of three years of experience are mandatory. Experience in a digital/advertising agency is an added bonus and will be highly regarded.",
      responsibilities: "To engage with potential customers, acquire new projects, broaden our market presence, and secure new business opportunities by taking proactive steps.",
      role: "Sales and Marketing Associate",
      location: "FULL-TIME, BENGALURU",
    },
    {
      id: "345",
      about: "Salary and benefits are commensurate with industry standards and candidate experience. We always strive to provide competitive compensation packages that reward and recognise the hard work and dedication of our employees.",
      qualifications: "Excellent communication skills and a minimum of three years of experience are mandatory. Experience in a digital/advertising agency is an added bonus and will be highly regarded.",
      responsibilities:
      "They are currently seeking a highly skilled Senior Frontend Engineer to join their team. Their company is dedicated to developing cutting-edge web applications for enterprise supply chain-related businesses, and they need someone who shares their passion for innovation and excellence.As a Senior Frontend Engineer, you will be responsible for developing and maintaining complex web applications, ensuring high performance and responsiveness of their applications. You will work closely with other team members and stakeholders to understand their needs and develop solutions that meet their requirements.",
      role: "Business Development Executive",
      location: "FULL-TIME, BENGALURU",
    },
    // {
    //   id: "123123",
    //   about: "Experience: 5+ years",
    //   qualifications: `Primary Skills:
    //   Angular, HTML / CSS, JavaScript, Nginx, TypeScript
    //   Assessment to be Cleared:
    //   JavaScript, Angular, HTML5 Semantics, Typescript`,
    //   responsibilities:
    //     "They are currently seeking a highly skilled Senior Frontend Engineer to join their team. Their company is dedicated to developing cutting-edge web applications for enterprise supply chain-related businesses, and they need someone who shares their passion for innovation and excellence.As a Senior Frontend Engineer, you will be responsible for developing and maintaining complex web applications, ensuring high performance and responsiveness of their applications. You will work closely with other team members and stakeholders to understand their needs and develop solutions that meet their requirements.",
    //   role: "Seo",
    //   location: "FULL-TIME, BENGALURU",
    // },
    {
      id: "345",
      about: "As Designer, you will be responsible for creating visual experience for web application, mobile application and brand",
      qualifications: "Proven graphic designing experience A strong portfolio of illustrations or other graphicsFamiliarity with design software and technologies (such as Illustrator, Photoshop, Figma) A keen eye for aesthetics and details Excellent communication skills Ability to work methodically and meet deadlines Degree in Design, Fine Arts or related field is a plus",
      responsibilities: "Our Hiring Philosophy: A hard worker and can-do attitude ready to learn, commitment to deliverables, timeline of product delivery is of utmost importance to us. We believe that the ability to learn is the greatest skill",
      role: "Graphic Designer",
      location: "FULL-TIME, BENGALURU",
    },
  ];

  const uniqueRoles = new Set(jobs.map((job) => job.role));
  return (
    <div className="career">
      <Helmet>
        <title>Career | Octech Digital - Pioneering Digital Technology Solutions</title>
      </Helmet>
      <Link to={ROUTES.HOME_PAGE}>
        <div className="back-home">
          <img src={CaretLeft} alt="back" />{" "}
          <div className="text">BACK TO HOME</div>{" "}
        </div>
      </Link>
      <div className="career-header">
        <div>
          <h2 className="career-titel">Careers</h2>
          <p className="career-sub">
          Are you ready to take the next step in your career journey? Join our dynamic and innovative team, where passion, creativity, and collaboration come together to drive meaningful impact. 
          </p>
        </div>
      </div>
      <div className="filter-wrap">
        <div className="roles-filter-container">
          {[...uniqueRoles].map((role) => (
            <div
              className={selectedRole === role ? "role selected" : "role"}
              onClick={() =>
                selectedRole === role
                  ? setSelectedRole()
                  : setSelectedRole(role)
              }
            >
              {role}
            </div>
          ))}
        </div>
      </div>
      {jobs
        .filter((job) => job.role === selectedRole || !selectedRole)
        .map((job) => (
          <div className="item">
            <div className="title">
              <div>
                <div className="role">{job.role}</div>
                <div className="location">{job.location}</div>
              </div>
              <div className="apply-container">
                <div>
                  <img src={arrowImg} alt="apply" />
                </div>
                <div className="apply-button">Apply</div>
              </div>
            </div>

            <div className="description">
              <div className="about">
                <div className="about-title"> About job </div>
                <div className="about-detail"> {job.about} </div>
              </div>
              <div className="qualifications">
                <div className="qualifications-title">
                  {" "}
                  What do you need for this opportunity?
                </div>
                <div className="qualifications-details">
                  {" "}
                  {job.qualifications}
                </div>
              </div>
              <div className="responsibilities">
                <div className="responsibilities-title">
                  {" "}
                  Roles and Responsibilities
                  <p>THE ROLE</p>
                </div>

                <div className="responsibilities-details">
                  {" "}
                  {job.responsibilities}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
