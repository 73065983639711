import './style.scss'
import service_image from '../../../../assets/servicePage/heropage/service_img.png'


export default function DevelopmentHeroSection() {
    return (
        <section className='service-heroSection'>
            <div className="service-main">
            <div className="service-heroSection_left">
                <h1>APP/WEB DEVELOPMENT</h1>
                <h2>With our significant effort put into the UI/UX of our apps, we seamlessly combine functionality and design to create an immersive and engaging experience</h2>
            </div>
            <div className="service-heroSection_right">
                <img src={service_image}/>
            </div>
            </div>


        </section>
    )
}