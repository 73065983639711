import './style.scss'
import service_image from '../../../../assets/servicePage/heropage/service_img.png'

// const data ={
//     Reward:{
//         heading: "REWARDS & PROMOTIONS",
//         description: "Rewards and promotions campaign contributes significantly to brand awareness by creating positive interactions and also spreading the word about the brand.",
//         image:service_image,
//     }
// }

export default function HeroSection() {
    return (
        <section className='service-heroSection'>
            <div className="service-main">
            <div className="service-heroSection_left">
                <h1>REWARDS & PROMOTIONS</h1>
                <h2>Rewards and promotions campaign contributes significantly to brand awareness by creating positive
                    interactions and also spreading the word about the brand.</h2>
                {/*<p>While other businesses export their operations overseas we believe that people do business with*/}
                {/*    people they know and </p>*/}
            </div>
            <div className="service-heroSection_right">
                <img src={service_image}/>
            </div>
            </div>


        </section>
    )
}