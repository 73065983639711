import './style.scss'
import SecurityCard from "../../components/card/SecurityCard";
import { securityserviceCardConst} from "../../../../constantFiles/constants";
import RewardsservicesCard from "../../../servicepage/components/servicesCard/rewardsservicesCard";
import SecurityservicesCard from "../../components/servicesCard/SecurityservicesCard";


export default function SecurityCardSection() {
    return (
        <section className="serviceCardSection">

            <div className="serviceCardSection_heading">
                <h1>our CYBER SECURITY services</h1>
            </div>
            <div className="security-serviceCardContent">
                {
                    securityserviceCardConst.map(({heading, calssNames, content, secoundHeading}) => (
                        // <SecurityCard heading={heading} img={img} calssNames={calssNames} img2={img2}
                        //               secoundHeading={secoundHeading} content={content}/>
                        <SecurityservicesCard title={heading} secondTitle={secoundHeading} alt={''} classh2={calssNames} classh1={''}
                                             description={content}
                                             image={''}/>
                    ))
                }
            </div>

        </section>
    )
}