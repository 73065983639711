import './style.scss'
import DigitalMCardSection from "./sections/cardSection/DigitalMCardSection";
import DigitalMHeroSection from "./sections/heroSection/DigitalMHeroSection";
import {DigitalMServiceSlider} from "./components/serviceSlider/DigitalMServiceSlider";
import DigitalMOurClients from "./sections/ourClints/DigitalMOurClients";
import {Helmet} from "react-helmet";


export default function DigitalMServices() {

    return(
        <section className="Services">
            <Helmet>
                <title>Digital Marketing Services | Octech Digital - Pioneering Digital Technology Solutions</title>
            </Helmet>
            <DigitalMHeroSection/>
            <DigitalMCardSection/>
            {/*<ServiceDescription/>*/}
            <DigitalMServiceSlider/>
            {/*<QnaDropdown/>*/}
            <DigitalMOurClients/>
        </section>
    )
}