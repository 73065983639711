import './style.scss'
import InternetThingCardSection from "./sections/cardSection/InternetThingCardSection";
import InternetThingHeroSection from "./sections/heroSection/InternetThingHeroSection";
import {InternetThingServiceSlider} from "./components/serviceSlider/InternetThingServiceSlider";
import InternetThingOurClients from "./sections/ourClints/InternetThingOurClients";
import {Helmet} from "react-helmet";


export default function InternetThingServices() {

    return(
        <section className="Services">
            <Helmet>
                <title>Internet Thing Services | Octech Digital - Pioneering Digital Technology Solutions</title>
            </Helmet>
            <InternetThingHeroSection/>
            <InternetThingCardSection/>
            {/*<ServiceDescription/>*/}
            <InternetThingServiceSlider/>
            {/*<QnaDropdown/>*/}
            <InternetThingOurClients/>
        </section>
    )
}