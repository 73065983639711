import './style.scss'
import DigitalMCard from "../../components/card/DigitalMCard";
import {digitalMarketingserviceCardConst} from "../../../../constantFiles/constants";
import digital from "../../../../assets/homePage/servicesProvide/Digital.svg";
import ServicesCard from "../../../homePage/components/servicesCard/servicesCard";
import DigitalMservicesCard from "../../components/servicesCard/DigitalMservicesCard";


export default function DigitalMCardSection() {
    return (
        <section className="serviceCardSection">

            <div className="serviceCardSection_heading">
                <h1>our DIGITAL MARKETING services</h1>
            </div>
            <div className="designserviceCardContent">
                {
                    digitalMarketingserviceCardConst.map(({heading, calssNames, content, secoundHeading}) => (
                        // <DigitalMCard heading={heading} img={img} calssNames={calssNames} img2={img2}
                        //             secoundHeading={secoundHeading} content={content}/>
                        <DigitalMservicesCard title={heading} secondTitle={secoundHeading} alt={''} classh2={calssNames} classh1={''}
                                              description={content}
                                              image={''}/>
                    ))
                }
            </div>

        </section>
    )
}