import './style.scss'
import DesignCardSection from "./sections/cardSection/DesignCardSection";
import DesignHeroSection from "./sections/heroSection/DesignHeroSection";
import {DesignServiceSlider} from "./components/serviceSlider/DesignServiceSlider";
import DesignOurClients from "./sections/ourClints/DesignOurClients";
import {Helmet} from "react-helmet";


export default function DesignServices() {

    return(
        <section className="Services">
            <Helmet>
                <title>Design Services | Octech Digital - Pioneering Digital Technology Solutions</title>
            </Helmet>
            <DesignHeroSection/>
            <DesignCardSection/>
            {/*<ServiceDescription/>*/}
            <DesignServiceSlider/>
            {/*<QnaDropdown/>*/}
            <DesignOurClients/>
        </section>
    )
}