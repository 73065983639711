import './style.scss'
import CloudCardSection from "./sections/cardSection/CloudCardSection";
import CloudHeroSection from "./sections/heroSection/CloudHeroSection";
import {CloudServiceSlider} from "./components/serviceSlider/CloudServiceSlider";
import CloudOurClients from "./sections/ourClints/CloudOurClients";
import {Helmet} from "react-helmet";


export default function CloudServices() {

    return(
        <section className="Services">
            <Helmet>
                <title>Cloud Services | Octech Digital - Pioneering Digital Technology Solutions</title>
            </Helmet>
            <CloudHeroSection/>
            <CloudCardSection/>
            {/*<ServiceDescription/>*/}
            <CloudServiceSlider/>
            {/*<QnaDropdown/>*/}
            <CloudOurClients/>
        </section>
    )
}