import './style.scss'
import facebook from '../../assets/homePage/footer/facebook.svg'
import linledin from '../../assets/homePage/footer/linkedin.svg'
import youtube from '../../assets/homePage/footer/youtube.svg'
import {Link, useLocation} from "react-router-dom";
import { ROUTES } from '../../lib/consts';
import iso from '../../assets/homePage/jobs/iso.png'



export default function Footer() {
    const location = useLocation()
    if (
        [ROUTES.CONTACTUS_PAGE, ROUTES.MAINSERVICE_PAGE].includes(location.pathname) &&
        window.innerWidth > 600
    )
        return null;
    return (
        <footer className="footer">
            <div className='upper-footer'>
                <div className='home '>
                    <h5>Home</h5>
                    <ul>
                        <li><Link to={"/MainServices"} id={'service'}>Services</Link></li>
                        <li><Link to={"/aboutus"}>About us</Link></li>
                        <li><Link to={"/ourworks"}>Our works</Link></li>
                        <li><Link to={"/careers"}>Career</Link></li>
                        {/*<li><Link to={"/comingSoon"}>Latest works</Link></li>*/}
                    </ul>
                </div>
                <div className='location '>
                    <h5>Location</h5>
                    <ul>
                        <li><a target={'_blank'} href={'https://www.google.co.in/maps/place/Optiv+Inc/@12.9496438,77.7248006,10z/data=!4m22!1m15!4m14!1m6!1m2!1s0x3bae1670c9b44e6d:0xf8dfc3e8517e4fe0!2z4KSs4KWH4KSC4KSX4KSy4KWB4KSw4KWBLCDgpJXgpLDgpY3gpKjgpL7gpJ_gpJU!2m2!1d77.5945627!2d12.9715987!1m6!1m2!1s0x3bae16bb2f0948d5:0x2e0f3d1af169d0dc!2z4KSR4KSq4KWN4KSf4KS_4KS1IOCkh-CkguCklSwgMTQzLCAxMHRoIENyb3NzIFJkLCBCaW5uYW1hbmdhbGEsIEhveXNhbGEgTmFnYXIsIEluZGlyYW5hZ2FyLCBCZW5nYWx1cnUsIEthcm5hdGFrYSA1NjAwMzg!2m2!1d77.6387869!2d12.9811832!3m5!1s0x3bae16bb2f0948d5:0x2e0f3d1af169d0dc!8m2!3d12.9811832!4d77.6387869!16s%2Fg%2F11c60mh47g?hl=en&entry=ttu'}>143, 10th Cross, Indiranagar, 1st Stage, Bangalore 560038</a></li>
                    </ul>
                    <img src={iso} alt='iso'/>
                </div>
                <div className='contact' id='contactUs'>
                    <h5>Contact</h5>
                    <ul>
                        <li><a href={'mailto:business@octech.in'} target={'_blank'}>business@octech.in</a></li>
                        <li><a href={'tel:+919019721012'} target={'_blank'}>+91 9019721012</a></li>
                    </ul>
                </div>
            </div>

            <div className='lower-footer'>
                <div className='lower-left'>
                  <p><a href={'https://www.privacypolicygenerator.info/live.php?token=lRKer26Ge4hX57lARBaGmnUdLrUcE79C'}
                  target={'-blank'}
                  >@ PRIVACY & POLICY</a></p>
                </div>
                <div className='lower-right'>
                   <p>Follow us</p>
                    <div className='social'>
                       <a href={'https://www.linkedin.com/company/octechdigital/'} target={'_blank'}> <img src={linledin} alt={'linkedin'}/></a>
                        <a href={'https://www.facebook.com/octechdigital'}target={'_blank'}>  <img src={facebook} alt={'facebook'}/></a>
                        <a href={'https://www.youtube.com/@octechdigital'}target={'_blank'}> <img src={youtube} alt={'youtube'}/></a>
                    </div>

                </div>
            </div>
        </footer>
    )
}