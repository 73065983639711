import './style.scss'
import service_image from '../../../../assets/servicePage/heropage/service_img.png'


export default function ProductHeroSection() {
    return (
        <section className='service-heroSection'>
            <div className="service-main">
            <div className="service-heroSection_left">
                <h1>PRODUCT</h1>
                <h2>Our innovation extends beyond campaigns to encompass our in house products, each tailored to emerging trends and current demands.</h2>
            </div>
            <div className="service-heroSection_right">
                <img src={service_image}/>
            </div>
            </div>


        </section>
    )
}