import './style.scss'
import CardSection from "./sections/cardSection/CardSection";
import HeroSection from "./sections/heroSection/HeroSection";
import {ServiceSlider} from "./components/serviceSlider/ServiceSlider";
import QnaDropdown from "./sections/qnaSection/QnaDropdown";
import OurClients from "./sections/ourClints/OurClients";
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";


export default function Services(props:any) {
    let { state } = useLocation();
    console.log(state)
    return(
        <section className="Services">
             <Helmet>
                 <title>Rewards & Promotions | Octech Digital - Pioneering Digital Technology Solutions</title>
             </Helmet>
            <HeroSection/>
            <CardSection/>
            <ServiceSlider/>
            <OurClients/>
        </section>
    )
}